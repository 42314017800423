$theme-colors: (
  "primary":                #ff0000,
  "secondary":              #4981ee,
  "success":                #5cb85c,
  "danger":                 #d9534f,
  "info":                   #5bc0de,
  "warning":                #f0ad4e,
  "light":                  #fff
);

$font-family-base:            'Roboto', Arial, sans-serif;
$font-weight-base:             300;
$font-weight-bolder:           500;
$headings-font-family:         'Roboto', Arial, sans-serif;
$headings-font-weight:         300;
$headings-margin-bottom:       1rem;
$h3-font-size:                 1.5rem;
$border-radius:                .25rem;
$border-radius-sm:             .15rem;
$border-radius-lg:             .25rem;

$navbar-dark-color:           rgba(255, 255, 255, 0.7);
$navbar-dark-hover-color:     rgba(255, 255, 255, 1);

$btn-font-weight:              500;
$btn-padding-x:                1rem;

$nav-link-padding-x:           0.75rem;

@import "../../../node_modules/bootstrap/scss/bootstrap";

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;500;700&display=swap');

/* Overrides here ... */

$main-purple:                 #6e26ae;

body { padding-top: 3.75rem; }

@media screen and (min-width: 768px) {
  body { padding-top: 4.25rem; }
}

@media screen and (min-width: 992px) {
  body { padding-top: 4.75rem; }
}

main a { font-weight: 500; }

@media (min-width: 992px) {
  h1 {
    font-size: 3.25rem;
    margin-bottom: 1rem;
  }
}

.header-area { background-color: #333; }

.header-area a { font-weight: 500; }

.header-area a:hover { text-decoration: none; }

.header-area .btn-outline-light {
  line-height: 28px;
  border-width: 2px;
  border-color: rgba(255,255,255,.6);
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.header-area .btn-outline-light:focus { color: #fff; }
.header-area .btn-outline-light:hover { color: #212529; }

.navbar-dark .navbar-toggler { border: 0; }

.navbar-dark .navbar-toggler:focus { outline: 0; }

.navbar-brand {
  font-family: "Signika Negative", sans-serif;
  font-weight: 600;
}

.logo {
  height: 30px;
  margin-bottom: 5px;
}

@media screen and (min-width: 768px) {
  .logo { height: 38px; }
}

.bg-header {
  position: relative;
  min-height: 90vh;
  color: #fff;
  background-color: $main-purple;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
}

.bg-header-color {
  background-color: #8f25ec;
  opacity: 0.7;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.bg-header-footer {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-size: contain;
  background-position-y: bottom;
  background-repeat: no-repeat;
}

.bg-header-content {
  height: 80%;
  p { font-size: 1.125rem; }
}

.section { padding: 1.5rem 0; }
.section-sm { padding: 3rem 0; }
.section-md { padding: 4.5rem 0; }
.section-lg { padding: 6rem 0; }

.bg-light-blue { background-color: #e7e7ff; }
.bg-light-gray { background-color: #f4f4f4; }

.section-heading {
  margin-bottom: 3rem;
  h2 {
    font-size: 2.25rem;
    color: $main-purple;
    text-shadow: 2px 2px 2px #fff;
  }
}

.single-icon { margin-bottom: 1rem; }

.card-footer {
  border-top: 0;
  background-color: transparent;
}

.card { border: 2px solid #f0f2f3; }

form, main { position: relative; }

.small-help {
  font-size: 13px;
  line-height: 1.4rem;
}

.loading {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(250,250,250,.7);
  z-index: 999;
}

.loading-logo { width: 60px; }

.card-body { overflow-x: auto; }

/* NOTIFICATIONS */
.notification-container-top-right { top: 30px; }

.notification-item { border-left: 0; }

.notification-item .notification-title {
  font-size: 16px;
  margin: 0 0 3px;
}

.notification-item .notification-close:after { font-size: 25px; }

/* COGNITO SELECT */
select[name=dial_code] {
  font-size: 16px;
  font-weight: 300;
  padding: 12px 20px 12px 12px;
}

a[data-test=sign-in-create-account-link],
a[data-test=sign-in-create-account-link]:hover {
  display: block;
  color: #ff0000;
  font-size: 18px;
  line-height: 1.5rem;
}

/* App full height screen */
html,
#root,
.app { height: 100% }

.app {
  display: flex;
  flex-direction: column;
}

main { flex: 1 1 auto; }

/* Stripe */
.form-control-stripe {
  background-color: #fff;
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;
}

/* ALERT CONFIRM */
.mini-alert-confirm {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
}

.mini-alert-confirm .box {
  width: calc(50% - 10px);
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%) scale(0.7);
  width: calc(100% - 20px);
  max-width: 400px;
  min-height: 100px;
  position: fixed;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.2);
  padding: 15px;
  text-align: center;
  transition: 0.3s;
  max-height: 460px;
  overflow-y: auto;
  border-radius: .25rem;
  background-color: #fff;
  color: #333;
}

.mini-alert-confirm .box .btn {
  margin: 0 4px;
  width: 40%;
}

.mini-alert-confirm .box .area {
  margin-bottom: 15px;
  font-size: 16px;
  white-space: pre-line;
}

.mini-alert-confirm .box .area small {
  text-transform: uppercase;
  opacity: 0.6;
  font-size: 18px;
}

.mini-alert-confirm .box .area b { font-size: 26px; }

.mini-alert-confirm.alert-enter,
.mini-alert-confirm.alert-enter-active,
.mini-alert-confirm.alert-enter-done {
  opacity: 1;
  visibility: visible;
  background-color: rgba(0,0,0,.3);
}

.mini-alert-confirm.alert-enter-active .box, .mini-alert-confirm.alert-enter-done .box {
  transform: translateY(-50%) translateX(-50%) scale(1);
}

.text-danger { color: #ff0000 !important; }

/* Custom Switch */

.custom-switch.inverted-switch .custom-control-label:before {
  color: #fff;
  border-color: #28a745;
  background-color: #28a745;
}

.custom-switch.inverted-switch .custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}

.custom-switch.inverted-switch .custom-control-input:checked~.custom-control-label::before {
  color: #fff;
  background-color: #fff;
  border: 1px solid #adb5bd;
}

.custom-switch.inverted-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #adb5bd;
  transform: translateX(0.05rem);
}

.custom-switch.inverted-switch .custom-control-input:focus:not(:checked) ~ .custom-control-label::before { border-color: #28a745; }

.custom-switch.inverted-switch .custom-control-input:focus ~ .custom-control-label::before { box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, .1); }

.custom-switch.inverted-switch .custom-control-input[disabled] ~ .custom-control-label::before,
.custom-switch.inverted-switch .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
  border-color: #adb5bd;
}

.custom-control-input[disabled] ~ .custom-control-label,
.custom-control-input:disabled ~ .custom-control-label { color: #bbb; }

/* Datepicker */
.flatpickr-input.form-control[readonly]:not(:disabled) { background-color: #fff; }

/* Input numbers */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}