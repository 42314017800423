body {
  font-family: "Roboto", Arial, sans-serif;
  font-size: 16px;
  line-height: 28px;
  font-weight: 300;
  color: #85919d;
  height: 100%;
  position: relative;
  background-color: #f9f9f9;
}
body.db-overflow {
  overflow-x: auto;
}

a {
  color: #ff0000;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}
a:hover, a:active, a:focus {
  color: #ff0000;
  outline: none;
  text-decoration: none;
}
a[aria-disabled="true"] {
  pointer-events: none;
  text-decoration: none;
}

p, figure {
  margin-bottom: 1.5em;
}

h1, h2, h3, h4, h5, h6 {
  color: #484c50;
  margin: 0 0 30px 0;
}

::-webkit-selection {
  color: #818892;
  background: #f9f6f0;
}

::-moz-selection {
  color: #818892;
  background: #f9f6f0;
}

::selection {
  color: #818892;
  background: #f9f6f0;
}

.btn {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}
.btn.btn-md {
  padding: 10px 20px !important;
}
.btn.btn-lg {
  padding: 18px 36px !important;
}
.btn:hover, .btn:active, .btn:focus {
  box-shadow: none !important;
  outline: none !important;
}

.form-control {
  box-shadow: none;
  border: 2px solid rgba(0, 0, 0, 0.1);
  height: 54px;
  font-size: 18px;
  font-weight: 300;
}
.form-control:active, .form-control:focus {
  outline: none;
  box-shadow: none;
  border-color: #ccc;
}

.db-loader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: #fff;
  background-size: 80px;
  text-align: center;
}

.header-hero {
  background-color: transparent;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  position: relative;
  width: 100%;
  height: 85vh;
}
@media screen and (max-width: 480px) {
  .header-hero { height: 450px; }
}
.header-hero .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #2a2d36;
  opacity: .54;
  z-index: 9;
}
.header-hero .container {
  display: flex;
  height: 100%;
  align-items: center;
}
.header-hero.header-img-1 {
  background-image: url("../images/header-bg/img1.jpg");
  background-position: top left;
}
.header-hero.header-img-2 {
  background-image: url("../images/header-bg/img2.jpg");
}
.header-hero.header-img-3 {
  background-image: url("../images/header-bg/img3.jpg");
  background-position: center left;
}
.header-hero.header-img-4 {
  background-image: url("../images/header-bg/img4.jpg");
}
.header-hero.header-img-5 {
  background-image: url("../images/header-bg/img5.jpg");
  background-position: bottom left;
}
.header-hero .db-intro {
  width: 100%;
  z-index: 10;
}
.header-hero .db-intro > div .db-left-position {
  width: 70%;
  float: left;
}
@media screen and (max-width: 992px) {
  .header-hero .db-intro > div .db-left-position {
    width: 100%;
  }
}
.header-hero .db-intro > div .db-center-position {
  width: 65%;
  text-align: center;
  margin: 0 auto;
}
@media screen and (max-width: 992px) {
  .header-hero .db-intro > div .db-center-position {
    width: 100%;
    text-align: center;
  }
}
.header-hero .db-intro > div .db-right-position {
  width: 65%;
  float: right;
  text-align: right;
}
@media screen and (max-width: 992px) {
  .header-hero .db-intro > div .db-right-position {
    width: 100%;
    text-align: left;
  }
}
.header-hero .db-intro > div h1 {
  font-size: 70px;
  line-height: 87px;
  font-weight: 100;
  color: white;
}
@media screen and (max-width: 992px) {
  .header-hero .db-intro .db-intro-text {
    text-align: center;
  }
  .header-hero .db-intro > div h1 {
    font-size: 40px;
    line-height: 57px;
  }
}
.header-hero .db-intro > div h3 {
  font-weight: 100;
  font-size: 30px;
  line-height: 45px;
  color: white;
}
@media screen and (max-width: 992px) {
  .header-hero .db-intro > div h3 {
    font-size: 24px;
    line-height: 37px;
  }
}
.header-hero .db-intro > div .btn-outline, .header-hero .db-intro > div .btn-primary {
  padding: 20px 30px !important;
  min-width: 200px;
  font-size: 18px;
}
@media screen and (max-width: 480px) {
  .header-hero .db-intro > div .btn-outline, .header-hero .db-intro > div .btn-primary {
    padding: 10px 30px !important;
    width: 100%;
  }
}
.header-hero .db-intro > div .btn-outline {
  border-color: rgba(255, 255, 255, 0.2);
  color: white;
  background-color: rgba(255, 255, 255, 0.1);
}
.header-hero .db-intro > div .btn-outline:hover {
  border-color: rgba(255, 255, 255, 0.4);
  color: white;
  background-color: rgba(255, 255, 255, 0.2);
}
.header-hero .db-learn-more {
  position: absolute;
  bottom: -25px;
  left: calc(50% - 46px);
  z-index: 12;
  text-align: center;
}
.header-hero .db-learn-more a {
  display: block;
}
.header-hero .db-learn-more a .text {
  display: block;
  color: rgba(255, 255, 255, 0.5);
  margin-bottom: 20px;
}
.header-hero .db-learn-more a .arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  margin: 0 auto;
  background-color: #ff0000;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;

}
.header-hero .db-learn-more a .arrow svg {
  color: #fff;
}

.header-hero-pages {
  height: 500px;
}
@media screen and (max-width: 768px) {
  .header-hero-pages {
    height: inherit;
    padding: 3em 0;
  }
}

.db-lead {
  font-size: 50px;
  font-weight: 300;
  color: #484c50;
  margin-bottom: 10px;
}
@media screen and (max-width: 768px) {
  .db-lead {
    font-size: 35px;
  }
}

.db-sub-lead {
  font-size: 18px;
  line-height: 32px;
  font-weight: 300;
}

.video-container {
  float: none;
  clear: both;
  width: 100%;
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;
}

.video-container.widescreen { padding-top:0; }

.video-container iframe {
  border:0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.cookie-hub {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  padding: 10px 15px;
  color: #fff;
  background-color: #333;
  font-size: 13px;
  line-height: 18px;
  z-index: 100;
}

.cookie-hub .cookie-text {
  padding-right: 20px;
}

.cookie-hub .btn {
  margin: 0 0 0 auto;
  font-size: 13px;
}

@media screen and (min-width: 992px) {
  .cookie-hub {
    right: 15px;
    bottom: 15px;
    left: auto;
    width: 30%;
  }
}

.features,
.features-2,
.features-3,
.features-4,
#db-projects,
#db-testimonials,
#db-about,
#db-subscribe,
#db-info,
.pricing,
#db-faqs {
  background-color: #fff;
  padding: 7em 0;
}
@media screen and (max-width: 768px) {
  .features,
  .features-2,
  .features-3,
  .features-4,
  #db-projects,
  #db-testimonials,
  #db-about,
  #db-subscribe,
  #db-info,
  .pricing,
  #db-faqs {
    padding: 3em 0;
  }
}

#db-projects {
  background: #f9f9f9;
}
#db-projects .db-lead {
  font-size: 50px;
  font-weight: 300;
  color: #484c50;
  margin-bottom: 10px;
}
@media screen and (max-width: 768px) {
  #db-projects .db-lead {
    font-size: 35px;
  }
}
#db-projects .db-sub-lead {
  font-size: 18px;
  line-height: 32px;
  font-weight: 300;
}
#db-projects .db-project-item {
  display: block;
  width: 100%;
  position: relative;
  background: #fff;
  overflow: hidden;
  z-index: 9;
  margin-bottom: 30px;
  -webkit-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  border-radius: 7px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
#db-projects .db-project-item img {
  z-index: 8;
  opacity: 1;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
#db-projects .db-project-item .db-text {
  padding: 30px 10px 10px 20px;
  text-align: center;
}
#db-projects .db-project-item .db-text h2, #db-projects .db-project-item .db-text span {
  text-decoration: none !important;
}
#db-projects .db-project-item .db-text h2 {
  font-size: 18px;
  font-weight: bold;
  margin: 0 0 10px 0;
  color: #484c50;
}
#db-projects .db-project-item .db-text span {
  color: #b3b3b3;
  font-size: 16px;
  font-weight: 400;
}
#db-projects .db-project-item .db-text p {
  color: #85919d;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}
#db-projects .db-project-item:hover, #db-projects .db-project-item:focus {
  -webkit-box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.1);
  text-decoration: none;
}
#db-projects .db-project-item:hover img, #db-projects .db-project-item:focus img {
  opacity: .7;
}
#db-projects .db-project-item:hover h2, #db-projects .db-project-item:hover span, #db-projects .db-project-item:focus h2, #db-projects .db-project-item:focus span {
  text-decoration: none !important;
}
#db-projects .db-project-item:hover p, #db-projects .db-project-item:focus p {
  color: #484c50;
}

.features .db-devices {
  margin-bottom: 40px;
}
.features .db-devices img {
  margin: 0 auto;
}
.features .db-lead {
  font-size: 50px;
  font-weight: 300;
  color: #484c50;
  margin-bottom: 10px;
}
@media screen and (max-width: 768px) {
  .features .db-lead {
    font-size: 35px;
  }
}
.features .db-sub-lead {
  font-size: 18px;
  line-height: 32px;
  font-weight: 300;
}
.features .db-feature {
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 30px;
  width: 100%;
}
@media screen and (max-width: 768px) {
  .features .db-feature {
    padding-left: 0px;
    padding-right: 0px;
  }
}
.features .db-feature .db-icon {
  width: 112px;
  height: 112px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 30px auto;
  background-color: #f0f0f0;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}
.features .db-feature .db-icon svg {
  color: #ff0000;
  width: 50px;
  height: 50px;
}
.features .db-feature h3 {
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 10px;
}

.features-2, .features-3, .features-4 {
  background: #f9f9f9;
  overflow: hidden;
}
.features-2 .db-label, .features-3 .db-label, .features-4 .db-label {
  display: block;
}
.features-2 .db-lead, .features-3 .db-lead, .features-4 .db-lead {
  font-size: 50px;
  font-weight: 300;
  color: #484c50;
  margin-bottom: 40px;
}
@media screen and (max-width: 768px) {
  .features-2 .db-lead, .features-3 .db-lead, .features-4 .db-lead {
    font-size: 35px;
  }
}
.features-2 .db-feature, .features-3 .db-feature, .features-4 .db-feature {
  clear: both;
  margin-bottom: 20px;
  float: left;
  width: 100%;
}
.features-2 .db-feature .db-icon, .features-3 .db-feature .db-icon, .features-4 .db-feature .db-icon {
  float: left;
  width: 10%;
}
.features-2 .db-feature .db-icon svg, .features-3 .db-feature .db-icon svg, .features-4 .db-feature .db-icon svg {
  width: 40px;
  height: 40px;
  color: #ff0000;
}
@media screen and (max-width: 768px) {
  .features-2 .db-feature .db-icon i, .features-3 .db-feature .db-icon i, .features-4 .db-feature .db-icon i {
    font-size: 30px;
  }
}
.features-2 .db-feature .db-text, .features-3 .db-feature .db-text, .features-4 .db-feature .db-text {
  float: right;
  width: 85%;
}
.features-2 .db-feature h3, .features-3 .db-feature h3, .features-4 .db-feature h3 {
  font-size: 24px;
  color: #484c50;
  margin-bottom: 10px;
  font-weight: 300;
}
.features-2 .db-btn-action, .features-3 .db-btn-action, .features-4 .db-btn-action {
  width: 100%;
  float: left;
}
.features-2 .db-btn-action > a, .features-3 .db-btn-action > a, .features-4 .db-btn-action > a {
  margin-left: 15%;
}
@media screen and (max-width: 480px) {
  .features-2 .db-btn-action > a, .features-3 .db-btn-action > a, .features-4 .db-btn-action > a {
    margin-left: 0;
  }
}
@media screen and (max-width: 991px) {
  .features-2 .db-feature-image img, .features-3 .db-feature-image img, .features-4 .db-feature-image img {
    max-width: 100%;
    height: auto;
  }
}

@media screen and (min-width: 992px) {
  .features-2 .db-feature-image img {
    margin-left: 60px;
    border: 5px solid #e6e6e6;
    border-radius: 15px;
  }
}

.features-3 {
  background: #fff;
}

.border-top {
  border-top: 1px solid #f0f2f3;
}

.content-logo {
  margin: 0 auto 10px;
  width: 250px;
}

.trademark-logo {
  font-size: 13px;
  margin-bottom: 40px;
}

#db-testimonials .db-lead {
  font-size: 50px;
  font-weight: 300;
  color: #484c50;
  margin-bottom: 10px;
}
@media screen and (max-width: 768px) {
  #db-testimonials .db-lead {
    font-size: 35px;
  }
}
#db-testimonials .flexslider {
  background: transparent;
  border: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
}
#db-testimonials .slides {
  z-index: 99;
}
#db-testimonials .slides li blockquote {
  border-left: none;
  padding-left: 0;
  font-size: 26px;
  line-height: 46px;
  margin-bottom: 0;
}
#db-testimonials .slides li p cite {
  font-size: 16px;
}
#db-testimonials .flexslider-controls {
  margin-left: -15px;
  z-index: 100;
  position: relative;
}
#db-testimonials .flexslider-controls .flex-control-nav {
  padding-bottom: 30px;
}
@media screen and (max-width: 768px) {
  #db-testimonials .flexslider-controls .flex-control-nav {
    padding-bottom: 30px;
  }
}
#db-testimonials .flexslider-controls .flex-control-nav li {
  cursor: pointer;
}
#db-testimonials .flexslider-controls .flex-control-nav li img {
  cursor: pointer;
  width: 50px;
  opacity: .3;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}
#db-testimonials .flexslider-controls .flex-control-nav li.flex-active img {
  opacity: 1;
}
#db-testimonials .flexslider-controls .flex-control-nav li:hover img {
  opacity: 1;
}

#db-about .db-social a {
  font-size: 35px !important;
  color: #b3b3b3 !important;
}
#db-about .db-social a:hover {
  color: #333333 !important;
}
#db-about .db-lead {
  font-size: 50px;
  font-weight: 300;
  color: #484c50;
  margin-bottom: 30px;
}
@media screen and (max-width: 768px) {
  #db-about .db-lead {
    font-size: 35px;
  }
}
#db-about .db-sub-lead {
  font-size: 18px;
  line-height: 32px;
  font-weight: 300;
}
#db-about .flexslider {
  background: transparent;
  border: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
}

#db-subscribe {
  background: #f9f9f9;
  padding: 3em 0;
}
#db-subscribe h3 {
  float: left;
  width: 30%;
  top: 10px;
  font-size: 26px;
  margin-bottom: 0;
  position: relative;
}
#db-subscribe h3 label {
  font-weight: 300;
}
@media screen and (max-width: 992px) {
  #db-subscribe h3 {
    width: 100%;
    margin-bottom: 30px;
  }
}
#db-subscribe form {
  width: 60%;
  float: right;
  position: relative;
}
@media screen and (max-width: 992px) {
  #db-subscribe form {
    width: 100%;
  }
}
#db-subscribe form .db-icon {
  position: absolute;
  top: 50%;
  margin-top: -10px;
  left: 15px;
  font-size: 20px;
}
@media screen and (max-width: 480px) {
  #db-subscribe form .db-icon {
    position: absolute;
    top: 26px;
  }
}
#db-subscribe form input[type="email"] {
  width: 100%;
  background: #ededed;
  border: none;
  padding-left: 50px;
  padding-right: 170px;
}
@media screen and (max-width: 480px) {
  #db-subscribe form input[type="email"] {
    margin-bottom: 10px;
    padding-right: 50px;
  }
}
#db-subscribe form input[type="submit"] {
  position: absolute;
  right: 0;
  top: 0;
  margin-right: -4px;
  height: 54px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  font-size: 20px;
  font-weight: 300;
  width: 160px;
}
@media screen and (max-width: 480px) {
  #db-subscribe form input[type="submit"] {
    position: relative;
    width: 100%;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
}

#db-info {
  background: #ff0000;
}
#db-info .db-label {
  color: rgba(255, 255, 255, 0.5);
}
#db-info .db-lead {
  color: white;
}
#db-info p {
  color: white;
}

.pricing {
  background-color: #f3f3f3;
  border-top: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
}
.pricing .price-box {
  background-color: #fff;
  border: 2px solid #e9e9e9;
  text-align: center;
  padding: 30px;
  margin-bottom: 40px;
  position: relative;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
}
.pricing .db-lead {
  margin-bottom: 40px;
}
.pricing .price-box.popular {
  border: 2px solid #ff0000;
}
.pricing .price-box.popular .popular-text {
  top: 0;
  left: 50%;
  margin-left: -54px;
  margin-top: -2em;
  position: absolute;
  padding: 4px 20px;
  background: #ff0000;
  color: #fff;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
}
.pricing .price-box.popular .popular-text:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -10px;
  border-top: 10px solid black;
  border-top-color: #ff0000;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
}
.pricing .pricing-plan {
  margin: 0 0 30px 0;
  padding: 0;
  font-size: 15px;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: 700;
}
.pricing .price {
  font-size: 50px;
  color: #000;
}
.pricing .price .currency {
  font-size: 20px;
  top: -1.2em;
}
.pricing .price small {
  font-size: 16px;
}
.pricing .pricing-info {
  padding: 0;
  margin: 0 0 30px 0;
}
.pricing .pricing-info li {
  padding: 0;
  margin: 0 0 4px;
  list-style: none;
  text-align: left;
}
@media screen and (min-width: 768px) {
  .pricing .plan-box {
    padding-right: 30px;
  }
  .pricing .details-box {
    padding-left: 30px;
    border-left: 1px solid #eee;
  }
}
#db-faqs .db-faq-list {
  margin: 0;
  padding: 0;
}
#db-faqs .db-faq-list li {
  margin: 0 0 40px 0;
  padding: 0;
  line-height: 24px;
  list-style: none;
}
@media screen and (max-width: 768px) {
  #db-faqs .db-faq-list li {
    margin: 0 0 20px 0;
  }
}
#db-faqs .db-faq-list li h2 {
  font-size: 30px;
  font-weight: 300;
  margin-bottom: 15px;
}
@media screen and (max-width: 768px) {
  #db-faqs .db-faq-list li h2 {
    font-size: 26px;
  }
}

#db-footer {
  background-color: #fff;
  border-top: 1px solid #f0f2f3;
}

#db-footer .container {
  padding-top: 5rem;
  padding-bottom: 3rem;
}

#db-footer a {
  color: #6a7885;
}
#db-footer a:hover {
  text-decoration: underline;
}
#db-footer h3 {
  font-size: 18px;
  font-weight: 400;
}
#db-footer .db-links {
  padding: 0;
  margin: 0;
}
#db-footer .db-links li {
  padding: 0;
  margin: 0;
  list-style: none;
  margin-bottom: 15px;
  line-height: 24px;
}
#db-footer .db-links li a {
  color: #6a7885;
}
#db-footer .db-links li a:hover {
  text-decoration: underline;
}
.footer-bottom-bar {
  border-top: 1px solid #f0f2f3;
  font-size: 15px;
  text-align: center;
  padding: 30px 0;
}
.footer-bottom-bar a {
  font-weight: 700;
}
.footer-bottom-bar p {
  margin-bottom: 0;
}

.db-social {
  padding: 0;
  margin: 0;
  margin-bottom: 30px;
}
.db-social li {
  padding: 0;
  margin: 0;
  display: inline;
}
.db-social li a {
  padding: 4px 7px;
  font-size: 22px;
}
.db-social li a:hover {
  text-decoration: none !important;
  color: #ff0000 !important;
}

.db-person {
  text-align: center;
}
@media screen and (max-width: 992px) {
  .db-person {
    margin-bottom: 30px;
    float: left;
    width: 100%;
  }
}
.db-person figure {
  margin: 0 auto 30px auto;
}
.db-person figure img {
  margin: 0 auto;
  display: block;
}
.db-person .db-name {
  margin-bottom: 0;
  color: #484c50;
}
.db-person .db-designation {
  color: #85919d;
}
.db-person .db-bio {
  text-align: left;
}

#db-offcanvas, .db-nav-toggle, #db-page {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

#db-offcanvas, .db-nav-toggle, #db-page {
  position: relative;
}

#db-page {
  z-index: 2;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}
.offcanvas-visible #db-page {
  -moz-transform: translateX(-275px);
  -webkit-transform: translateX(-275px);
  -ms-transform: translateX(-275px);
  -o-transform: translateX(-275px);
  transform: translateX(-275px);
}

#db-offcanvas {
  display: none;
  height: 100%;
  right: 0;
  overflow-y: auto;
  position: fixed;
  z-index: 1;
  top: 0;
  width: 275px;
  background: black;
  padding: 30px;
}
@media screen and (max-width: 768px) {
  #db-offcanvas {
    display: block;
  }
}
#db-offcanvas ul {
  padding: 0;
  margin: 0;
}
#db-offcanvas ul li {
  padding: 0;
  margin: 0 0 10px 0;
  list-style: none;
  line-height: 28px;
}
#db-offcanvas ul li a {
  font-size: 18px;
  color: rgba(255, 255, 255, 0.7);
}
#db-offcanvas ul li.active a {
  color: #ff0000;
}

.db-nav-toggle {
  cursor: pointer;
  text-decoration: none;
}
.db-nav-toggle.active i::before, .db-nav-toggle.active i::after {
  background: #fff;
}
.db-nav-toggle.dark.active i::before, .db-nav-toggle.dark.active i::after {
  background: #fff;
}
.db-nav-toggle:hover, .db-nav-toggle:focus, .db-nav-toggle:active {
  outline: none;
  border-bottom: none !important;
}
.db-nav-toggle i {
  position: relative;
  display: -moz-inline-stack;
  display: inline-block;
  zoom: 1;
  *display: inline;
  width: 30px;
  height: 2px;
  color: #fff;
  font: bold 14px/.4 Helvetica;
  text-transform: uppercase;
  text-indent: -55px;
  background: #fff;
  transition: all .2s ease-out;
}
.db-nav-toggle i::before, .db-nav-toggle i::after {
  content: '';
  width: 30px;
  height: 2px;
  background: #fff;
  position: absolute;
  left: 0;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
}
.db-nav-toggle.dark i {
  position: relative;
  color: #000;
  background: #000;
  transition: all .2s ease-out;
}
.db-nav-toggle.dark i::before, .db-nav-toggle.dark i::after {
  background: #000;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
}

.db-nav-toggle i::before {
  top: -7px;
}

.db-nav-toggle i::after {
  bottom: -7px;
}

.db-nav-toggle:hover i::before {
  top: -10px;
}

.db-nav-toggle:hover i::after {
  bottom: -10px;
}

.db-nav-toggle.active i {
  background: transparent;
}

.db-nav-toggle.active i::before {
  top: 0;
  -webkit-transform: rotateZ(45deg);
  -moz-transform: rotateZ(45deg);
  -ms-transform: rotateZ(45deg);
  -o-transform: rotateZ(45deg);
  transform: rotateZ(45deg);
}

.db-nav-toggle.active i::after {
  bottom: 0;
  -webkit-transform: rotateZ(-45deg);
  -moz-transform: rotateZ(-45deg);
  -ms-transform: rotateZ(-45deg);
  -o-transform: rotateZ(-45deg);
  transform: rotateZ(-45deg);
}

.db-nav-toggle {
  position: fixed;
  top: 40px;
  right: 20px;
  z-index: 9999;
  display: block;
  margin: 0 auto;
  display: none;
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  .db-nav-toggle {
    display: block;
    top: 20px;
  }
}

.mfp-with-zoom .mfp-container,
.mfp-with-zoom.mfp-bg {
  opacity: 0;
  -webkit-backface-visibility: hidden;
  /* ideally, transition speed should match zoom duration */
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.mfp-with-zoom.mfp-ready .mfp-container {
  opacity: 1;
}

.mfp-with-zoom.mfp-ready.mfp-bg {
  opacity: 0.8;
}

.mfp-with-zoom.mfp-removing .mfp-container,
.mfp-with-zoom.mfp-removing.mfp-bg {
  opacity: 0;
}

@media screen and (max-width: 480px) {
  .col-xxs-12 {
    float: none;
    width: 100%;
  }
}

.btn-cta {
  padding: 20px 30px !important;
  width: 200px;
  font-size: 18px;
}
@media screen and (max-width: 480px) {
  .btn-cta {
    padding: 10px 30px !important;
    width: 100%;
  }
}

.row-bottom-padded-lg {
  padding-bottom: 7em;
}
@media screen and (max-width: 768px) {
  .row-bottom-padded-lg {
    padding-bottom: 2em;
  }
}

.row-top-padded-lg {
  padding-top: 7em;
}
@media screen and (max-width: 768px) {
  .row-top-padded-lg {
    padding-top: 2em;
  }
}

.row-bottom-padded-md {
  padding-bottom: 4em;
}
@media screen and (max-width: 768px) {
  .row-bottom-padded-md {
    padding-bottom: 2em;
  }
}

.row-top-padded-md {
  padding-top: 4em;
}
@media screen and (max-width: 768px) {
  .row-top-padded-md {
    padding-top: 2em;
  }
}

.row-bottom-padded-sm {
  padding-bottom: 2em;
}
@media screen and (max-width: 768px) {
  .row-bottom-padded-sm {
    padding-bottom: 2em;
  }
}

.row-top-padded-sm {
  padding-top: 2em;
}
@media screen and (max-width: 768px) {
  .row-top-padded-sm {
    padding-top: 2em;
  }
}
